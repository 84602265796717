<template>
	<v-app style='background:transparent !important'>
		<icons/>
		<router-view :key="$route.path + num"></router-view>
	</v-app>
</template>

<style>	

	.v-list-item.primary--text.v-list-item--active.v-list-item--link.theme--light{
		color:inherit !important;
		caret-color:inherit !important;
	}

	html, body{
		overflow-y:auto !important;
	}

	.no-padding{
		padding:0 !important;
	}
	.f-400{
		font-weight: 400;
	}
	.f-600{
		font-weight: 600;
	}
	.v-card__title {
		font-size: 1.1rem;
		font-weight: 400;
		word-break: break-all;
	}
	.v-application{
		line-height: 1.3;
	}
	.v-application .red {
		background-color: #f8192c !important;
		border-color: #f8192c !important;
	}

	.nowrap{
		white-space: nowrap;
	}
	.upper{
		text-transform:uppercase;
	}


	/* esto afecta a los gráficos de dona */
	.v-application{
		font-family:'Poppins', 'Roboto',sans-serif !important;
	}
	.v-application h2{
		font-weight:500;
	}
</style>

<script>
import Icons from './components/Icons'
export default {
	components: {
		Icons
	},
	data(){
		this.vars = {
			num: 0
		}
		window.forceRender = ()=>{
			this.vars.num++
		}
		return this.vars
	}
}
</script>
