import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let base = "/ui"
if(location.pathname.startsWith("/site/inovanex-player"))
	base = "/site/inovanex-player/ui"

export default new Router({
	mode: 'history',
	base,
	routes: [

		{
			path: '/login',
			name: 'login',
			component: ()=> import("../views/Panel/Login.vue")
		},
		{
			path: '/board',
			name: 'board',
			component: ()=> import("../views/Panel/Config.vue")
		},
		{
			path: '/configuracion',
			name: 'configuracion',
			component: ()=> import("../views/Panel/Config.vue")
		},
		{
			path: '/configuracion/crear',
			name: 'configuracionCrear',
			component: ()=> import("../views/Panel/ConfigCreate.vue")
		},
		{
			path: '/configuracion/:id',
			name: 'configuracionCrear2',
			component: ()=> import("../views/Panel/ConfigCreate.vue")
		},
		{
			path: '/dominios',
			name: 'dominios',
			component: ()=> import("../views/Panel/Dominios.vue")
		},
		{
			path: '/dominio/crear',
			name: 'dominioCrear',
			component: ()=> import("../views/Panel/DominioCrear.vue")
		},
		{
			path: '/configuracion/:id',
			name: 'dominioEditar',
			component: ()=> import("../views/Panel/DominioCrear.vue")
		},
		{
			path: '/temas',
			name: 'temas',
			component: ()=> import("../views/Panel/Temas.vue")
		},
		{
			path: '/tema/crear',
			name: 'temaCrear',
			component: ()=> import("../views/Panel/TemaCrear.vue")
		},
		{
			path: '/tema/:id',
			name: 'temaEditar',
			component: ()=> import("../views/Panel/TemaCrear.vue")
		},
		{
			path: '/micuenta',
			name: 'micuenta',
			component: ()=> import("../views/Panel/Password.vue")
		},
		{
			path: '/generador',
			name: 'generador',
			component: ()=> import("../views/Generador.vue")
		}
	]
})
