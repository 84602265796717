import Vue from 'vue'
//import VueMask from 'v-mask'
import { Buffer } from 'buffer'
import vuetify from  './plugins/vuetify'
import App from './App.vue'
import router from './plugins/router'
//import { VueMaskDirective } from 'v-mask'

import axios from 'axios'


// @ts-ignore
window.Buffer = Buffer
if(location.host.indexOf(":") >= 0){
	axios.defaults.baseURL = "http://localhost:40801/site/inovanex-player"
}
else{
	axios.defaults.baseURL = "/site/inovanex-player"
}


//Vue.directive('mask', VueMaskDirective)
window["siteId"] = "inovanex-player"

if(location.host.indexOf(":") >= 0){
	window["service"] = {
		base: "http://localhost:40801/site/inovanex-player",
		ui: "http://localhost:40801/site/inovanex-player/ui",
		api:"http://localhost:40801/site/inovanex-player/api"
	}
}
else{
	window["service"] = {
		base: "/site/inovanex-player",
		ui: "/site/inovanex-player/ui",
		api: "/site/inovanex-player/api"
	}
}

Vue.config.productionTip = false



// @ts-ignore
let params:any = {
	vuetify,
	router,
	render: h => h(App)
}
new Vue(params).$mount('#app')
