import Vue from 'vue'
import Vuetify from 'vuetify'
import IconComponent from '../components/IconComponent.vue'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)
export default new Vuetify({

    icons: {
        component: IconComponent
    },

    theme: {
        themes:{
            light: {
                primary: "red"
            },
            dark: {
                primary: 'red'
            }
        }
    }
})
